import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { API_BASE_URL } from '../config';
import '../styles/general.css'; 
import '../styles/professionsearch.css'; 

const BusinessOnboarding01 = () => {
  const [selectedBusinessType, setSelectedBusinessType] = useState(null);
  const [selectedScope, setSelectedScope] = useState({
    residential: false,
    commercial: false,
    government: false,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const MAX_RESULTS = 30;

  const Spacer = ({ height }) => {
    return <div style={{ height }} />;
  };

  // Handle profession type selection
  const handleBusinessTypeSelect = (type) => {
    setSelectedBusinessType(type); // Only one selection at a time
  };

  // Handle scope selection (multiple choices allowed)
  const handleScopeToggle = (scope) => {
    setSelectedScope((prevScope) => ({
      ...prevScope,
      [scope]: !prevScope[scope],
    }));
  };

  // Handle typing in the search box and dynamically fetch professions
  const handleSearchChange = async (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    if (searchValue.length > 0) {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/Profession/searchprofessions?search=${searchValue}`);
        setResults(response.data.slice(0, MAX_RESULTS)); // Limit to MAX_RESULTS
      } catch (error) {
        console.error('Error fetching professions:', error);
      }
    } else {
      setResults([]);
    }
  };

  
  // Clear both search term and results
const handleClear = () => {
  setSearchTerm('');    // Clear the search input
  setResults([]);       // Clear the results
};

  // Add selected profession to the list
  const addItem = (item) => {
    if (!selectedItems.some((p) => p.id === item.id)) {
      setSelectedItems((prevSelected) => [...prevSelected, item]);
    }
  };


  // Remove selected profession from the list
  const removeItem = (itemId) => {
    setSelectedItems(selectedItems.filter((p) => p.id !== itemId));
  };

  const handleSubmit = async () => {
    if (!selectedBusinessType) {
      alert('Please select a profession type.');
      return;
    }
  
    if (!selectedScope.residential && !selectedScope.commercial && !selectedScope.government) {
      alert('Please select at least one scope of work.');
      return;
    }
  
    if (selectedItems.length === 0) {
      alert('Please select at least one profession.');
      return;
    }
  
    const data = {
      businessid: 1,
      businesstype: selectedBusinessType,
      residential: selectedScope.residential ? 1 : 0,
      commercial: selectedScope.commercial ? 1 : 0,
      government: selectedScope.government ? 1 : 0,
      professionIds: selectedItems
        .filter(item => item.type === 'Profession')
        .map((item) => item.id), // Filter and map profession IDs
      ancillaryIds: selectedItems
        .filter(item => item.type === 'Ancillary')
        .map((item) => item.id) // Filter and map ancillary IDs
    };
  
    try {
      await axios.post(`${API_BASE_URL}/api/BusinessOnboarding/saveBusinessOnboarding01`, data);
      alert('Business onboarding saved successfully!');
    } catch (error) {
      console.error('Error saving business onboarding:', error);
      alert('An error occurred while saving. Please try again.');
    }
  };

  return (
    <div className="business-onboarding-container">
      <h1>What is your main profession?</h1>

      <h3>Select your profession type</h3>
      <div className="profession-type-container">
        {['Trade Professional', 'Building Professional', 'Supplier', 'Ancillary'].map((type) => (
          <button
            key={type}
            className={`profession-type-button ${selectedBusinessType === type ? 'selected' : ''}`}
            onClick={() => handleBusinessTypeSelect(type)}
          >
            {type}
          </button>
        ))}
      </div>
      <p className="profession-note">Ancillary: Lawyers, Real Estate Agents, Mortgage Brokers etc</p>

      <Spacer height="20px" />
      <h3>What is the scope of work you do?</h3>
      <div className="scope-container">
        {['residential', 'commercial', 'government'].map((scope) => (
          <button
            key={scope}
            className={`scope-button ${selectedScope[scope] ? 'selected' : ''}`}
            onClick={() => handleScopeToggle(scope)}
          >
            {scope.charAt(0).toUpperCase() + scope.slice(1)}
          </button>
        ))}
      </div>

      <Spacer height="20px" />

      <h3>Add one or more profession categories</h3>
      <div className="frame-6-1 frame-6-6">
      <div className="search-box">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Profession search"
          className="search-input"
        />
        <button onClick={handleClear} className="clear-button">×</button>
      </div>

      {results.length > 0 && (
        <ul className="dropdown-results2">
          {results.map((item) => (
            <li key={`${item.type}-${item.id}`}  className="result-item">
              <div className="result-item-content">
                <span>{item.name} ({item.type})</span>
              </div>
              <button onClick={() => addItem(item)} className="add-button">
                +
              </button>
            </li>
          ))}
        </ul>
      )}

      </div>
    <Spacer height="20px" />



      <div className="selected-items">
      <h4>Selected Professions</h4>
      <div className="item-tags" style={{ minHeight: '200px', border: '2px dashed #ccc', borderRadius: '10px', padding: '10px', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
        {selectedItems.length > 0 ? (
          selectedItems.map((item) => (
            <div key={`${item.type}-${item.id}`} className="item-tag" style={{ height: '50px',backgroundColor: '#f0f0f0', borderRadius: '20px', padding: '10px 15px', margin: '5px', display: 'flex', alignItems: 'center' }}>
              <span>{item.name}</span>
              <button onClick={() => removeItem(item.id)} className="remove-button" style={{ background: 'none', border: 'none', marginLeft: '10px', cursor: 'pointer' }}>×</button>
            </div>
          ))
        ) : (
          <p style={{ color: '#999' }}>No services selected</p>
        )}
      </div>
    </div>

      <button onClick={handleSubmit} className="submit-button">Next</button>
    </div>
  );
};

export default BusinessOnboarding01;