import React from "react";
import "./addjobcompleted.css";

export const AddjobsCompleted = () => {
  return (
    <div className="addjobs-completed">
      <div className="rectangle" />
      <div className="text-content-title">
        <p className="title">Congratulations! Your job has been posted!</p>
        <p className="subtitle">
          Relevant tradies are being contacted and you will soon receive message and or notifications
        </p>
      </div>
      <p className="job-details-postcode">
        <span className="text-wrapper">
          Job Details
          <br />
        </span>
        <span className="span">
          <br />
        </span>
        <span className="text-wrapper">Postcode</span>
        <span className="span">
          : 2166
          <br />
        </span>
        <span className="text-wrapper">Description</span>
        <span className="span">
          : We need a wardrobe person to provide a quote for a standard mirrored wardrobe 2m x 3m
          <br />
          <br />
        </span>
        <span className="text-wrapper">Budget</span>
        <span className="span">
          : $500
          <br />
        </span>
        <span className="text-wrapper">Time</span>
        <span className="span">
          : This week
          <br />
        </span>
        <span className="text-wrapper">Site Visits</span>
        <span className="span">: Weekdays</span>
      </p>
      <img src="/img/img-menu3.png" alt="Menu" />
      <div className="input">
        <div className="div">Finished</div>
      </div>
    </div>
  );
};


export default AddjobsCompleted;