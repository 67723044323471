import React from "react";
import { BuildingBlocks } from "../components/BuildingBlocks";
import { BuildingBlocksWrapper } from "../components/BuildingBlocksWrapper";
import { SelectField } from "../components/SelectField";
import "./businessjobsdashboard.css";

export const BusinessJobsDashboard = () => {
  return (
    <div className="professional-jobs">
      <div className="section-header">
        <header className="header">
          <div className="frame">
            <img className="codesandbox" alt="Codesandbox" src="/img/codesandbox.svg" />
            <div className="text-wrapper-2">Build Start</div>
          </div>
          <div className="navigation-pill-list">
            <div className="navigation-pill">
              <div className="title">Home</div>
            </div>
            <div className="title-wrapper">
              <div className="title-2">Jobs</div>
            </div>
            <div className="div-wrapper">
              <div className="title">Browse Directory</div>
            </div>
            <div className="navigation-pill-2">
              <div className="title">Social Platform</div>
            </div>
            <div className="navigation-pill-3">
              <div className="title">Messages</div>
            </div>
          </div>
          <div className="user-wrapper">
          <img src="/img/img-profile.png" alt="Profile" />
          </div>
        </header>
      </div>
      <div className="frame-wrapper">
        <div className="frame-2">
          <div className="frame-3">
            <div className="frame-4">
              <div className="text-content-title">
                <div className="title-3">View Jobs</div>
                <p className="subtitle">Here are the jobs that match your business</p>
              </div>
              <button className="button">
                <div className="text-wrapper-3">Subscribe today</div>
              </button>
            </div>
            <div className="frame-5">
              <div className="messages-list">
                <div className="left-wrapper">
                  <div className="left">
                    <div className="label-2">Job Feed</div>
                  </div>
                </div>
                <div className="left-wrapper">
                  <div className="left">
                    <div className="label-3">Search</div>
                  </div>
                </div>
                <div className="list-item">
                  <BuildingBlocks className="state-layer-overlay" />
                  <div className="state-layer">
                    <div className="leading-element">
                      <BuildingBlocksWrapper
                        className="building-blocks-image-thumbnail"
                        thumbnail="/img/thumbnail-77.png"
                        thumbnailClassName="building-blocks-instance"
                      />
                    </div>
                    <div className="content">
                      <div className="headline">Install Oven</div>
                      <p className="supporting-text">Supporting line text lorem ipsum dolor sit amet, consectetur.</p>
                    </div>
                    <div className="trailing-supporting">10 min</div>
                  </div>
                </div>
                <div className="list-item-2">
                  <BuildingBlocks className="state-layer-overlay" />
                  <div className="state-layer">
                    <div className="leading-element">
                      <BuildingBlocksWrapper
                        className="building-blocks-image-thumbnail"
                        thumbnail="/img/thumbnail-77.png"
                        thumbnailClassName="building-blocks-instance"
                      />
                    </div>
                    <div className="content">
                      <div className="headline">Power pole install</div>
                      <p className="p">Supporting line text lorem ipsum dolor sit amet, consectetur.</p>
                    </div>
                    <div className="trailing-supporting-2">10 min</div>
                  </div>
                </div>
                <div className="list-item-2">
                  <BuildingBlocks className="state-layer-overlay" />
                  <div className="state-layer">
                    <div className="leading-element">
                      <BuildingBlocksWrapper
                        className="building-blocks-image-thumbnail"
                        thumbnail="/img/thumbnail-77.png"
                        thumbnailClassName="building-blocks-instance"
                      />
                    </div>
                    <div className="content">
                      <div className="headline">Install new downlights</div>
                      <p className="p">Supporting line text lorem ipsum dolor sit amet, consectetur.</p>
                    </div>
                    <div className="trailing-supporting-2">10 min</div>
                  </div>
                </div>
                <div className="list-item-2">
                  <BuildingBlocks className="state-layer-overlay" />
                  <div className="state-layer">
                    <div className="leading-element">
                      <BuildingBlocksWrapper
                        className="building-blocks-image-thumbnail"
                        thumbnail="/img/thumbnail-77.png"
                        thumbnailClassName="building-blocks-instance"
                      />
                    </div>
                    <div className="content">
                      <div className="headline">Job Title</div>
                      <p className="p">Supporting line text lorem ipsum dolor sit amet, consectetur.</p>
                    </div>
                    <div className="trailing-supporting-2">10 min</div>
                  </div>
                </div>
                <div className="list-item-2">
                  <BuildingBlocks className="state-layer-overlay" />
                  <div className="state-layer">
                    <div className="leading-element">
                      <BuildingBlocksWrapper
                        className="building-blocks-image-thumbnail"
                        thumbnail="/img/thumbnail-77.png"
                        thumbnailClassName="building-blocks-instance"
                      />
                    </div>
                    <div className="content">
                      <div className="headline">Job TItle</div>
                      <p className="p">Supporting line text lorem ipsum dolor sit amet, consectetur.</p>
                    </div>
                    <div className="trailing-supporting-2">10 min</div>
                  </div>
                </div>
                <div className="list-item-2">
                  <BuildingBlocks className="state-layer-overlay" />
                  <div className="state-layer">
                    <div className="leading-element">
                      <BuildingBlocksWrapper
                        className="building-blocks-image-thumbnail"
                        thumbnail="/img/thumbnail-77.png"
                        thumbnailClassName="building-blocks-instance"
                      />
                    </div>
                    <div className="content">
                      <div className="headline">Job TItle</div>
                      <p className="p">Supporting line text lorem ipsum dolor sit amet, consectetur.</p>
                    </div>
                    <div className="trailing-supporting-2">10 min</div>
                  </div>
                </div>
                <div className="list-item-2">
                  <BuildingBlocks className="state-layer-overlay" />
                  <div className="state-layer">
                    <div className="leading-element">
                      <BuildingBlocksWrapper
                        className="building-blocks-image-thumbnail"
                        thumbnail="/img/thumbnail-77.png"
                        thumbnailClassName="building-blocks-instance"
                      />
                    </div>
                    <div className="content">
                      <div className="headline">Name</div>
                      <p className="p">Supporting line text lorem ipsum dolor sit amet, consectetur.</p>
                    </div>
                    <div className="trailing-supporting-2">10 min</div>
                  </div>
                </div>
              </div>
              <div className="frame-6">
                <div className="frame-top">
                  <div className="subtitle-2">Install Oven</div>
                  <div className="div-2">
                  <img src="/img/img-mail.png" alt="Mail" />
                    <div className="text-wrapper-4">Send Response</div>
                  </div>
                  <div className="div-2">
                  <img src="/img/img-dollar.png" alt="Dollar" />
                    <div className="text-wrapper-4">
                      Quote <br />
                      Job
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <div className="text-wrapper-5">Postcode</div>
                  <div className="input">
                    <div className="text-wrapper-6">2166</div>
                  </div>
                </div>
                <div className="input-field-2">
                  <div className="text-wrapper-5">Job Description</div>
                  <div className="input-2">
                    <p className="text-wrapper-6">
                      We need a wardrobe person to provide a quote for a standard mirrored wardrobe 2m x 3m
                    </p>
                  </div>
                </div>
                <div className="frame-7">
                  <div className="input-field-3">
                    <div className="text-wrapper-5">Photos</div>
                  </div>
                  <div className="frame-8">
                    <div className="rectangle" />
                    <div className="rectangle" />
                    <div className="rectangle" />
                    <div className="rectangle" />
                  </div>
                </div>
                <div className="frame-8">
                  <div className="input-field-4">
                    <div className="text-wrapper-7">Budget</div>
                    <div className="input-3">
                      <div className="text-wrapper-8">$500</div>
                    </div>
                  </div>
                  <div className="input-field-5">
                    <p className="text-wrapper-9">When does it need to be done</p>
                    <div className="input-4">
                      <div className="text-wrapper-10">This week</div>
                    </div>
                  </div>
                </div>
                <SelectField
                  className="select-field-instance"
                  label="Site Visit times"
                  selectClassName="design-component-instance-node"
                  state="default"
                  value="Anytime"
                  valueType="default"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessJobsDashboard;