import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { GoogleMap, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api';
import { API_BASE_URL } from '../config';
import Navbar from '../components/navybar';
import FilterForm from './filterForm'; 
import '../styles/mapstyle.css';


// Helper function to parse URL query parameters
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const MapComponent = ({ locations, center }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyC0yrTTVQEOV6b64bAVP-UnVZNjfJibmh8", // Replace with your Google Maps API Key
  });
   
  const [activeMarker, setActiveMarker] = useState(null);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap
      center={center}
      zoom={13}
      mapContainerStyle={{ width: '100%', height: '100%' }}  // Full height to fill RHS
    >
      {locations.map((location, index) => (
        <Marker
          key={index}
          position={{ lat: location.latitude, lng: location.longitude }}
          onClick={() => setActiveMarker(index)}
        >
          {activeMarker === index && (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div style={{ backgroundColor: 'white', padding: '10px', borderRadius: '5px' }}>
                <h4>{location.businessName}</h4>
                <p>
                  <strong>Phone:</strong> {location.phone || 'N/A'}
                </p>
                <p>
                  <strong>Address:</strong> {location.streetNumber} {location.streetName}, {location.suburb}, {location.myState}, {location.postCode}
                </p>
              </div>
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  );
};

const MapSearch = () => {
  const query = useQuery();
  const searchTermFromQuery = query.get('searchTerm');
  const postCodeFromQuery = query.get('postCode');

  const [searchTerm, setSearchTerm] = useState(searchTermFromQuery || '');
  const [postCode, setPostCode] = useState(postCodeFromQuery || '');

  const [professionId, setProfessionId] = useState('');
  const [accreditations, setAccreditations] = useState([]);

  const [results, setResults] = useState([]); // For final search results
  const [professionResults, setProfessionResults] = useState([]); // For profession search results
  const [locations, setLocations] = useState([]);
  const [professionids, setProfessionIds] = useState([]);
  const [center, setCenter] = useState({ lat: -33.8688, lng: 151.2093 }); // Default center
  const [showFilters, setShowFilters] = useState(false);
  
  // Initial state to be passed to the filter form
  const [filters, setFilters] = useState({
    keywordSearch: '',
    within: '25km',
    selectedAccreditations: [],
    workScope: {},
    ratings: '',
    licensed: false,
    insured: false,
    options: {},
    // Add more filters as needed
  });

  const MAX_RESULTS = 20;

  useEffect(() => {
    // Function to perform search when query parameters are available (i.e., on page load or when URL changes)
    const performSearchFromQuery = async () => {
      if (searchTermFromQuery && postCodeFromQuery) {
        try {
          const response = await axios.post(`${API_BASE_URL}/api/search/searchprofpostcode`, {
            searchTerm: searchTermFromQuery,
            postCode: postCodeFromQuery,
          });
  
          setResults(response.data.businesses); // Final search results
          setLocations(response.data.locations);
          setProfessionIds(response.data.professions);
          setAccreditations(response.data.certifications);

          // Update the filters state, specifically the accreditations array
          //setFilters(prevFilters => ({
          //  ...prevFilters,               // Keep the existing filters intact
          //  selectedAccreditations: response.data.certifications,        // Update the accreditations array with the fetched certs
          //}));

  
          if (response.data.center) {
            setCenter({
              lat: response.data.center.latitude,
              lng: response.data.center.longitude,
            });
          }
        } catch (error) {
          console.error('Error performing search:', error);
        }
      }
    };
  
    performSearchFromQuery(); // Perform search if searchTerm and postCode are provided in the query
  }, [searchTermFromQuery, postCodeFromQuery]); // Trigger when query parameters change



  // Function to handle filtered search (when the apply filters button is pressed)
  const handleFilterSearch = async (appliedFilters) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/search/searchwithfilters`, {
        searchTerm: searchTerm,
        keywordSearch: appliedFilters.keywordSearch, // Use search term or keyword
        postCode: appliedFilters.postcode || postCode,
        //within: appliedFilters.within,
        workScope: appliedFilters.workScope,
        ratings: appliedFilters.ratings,
        options: appliedFilters.options,
        selectedAccreditations: appliedFilters.selectedAccreditations,
      });

      setResults(response.data.businesses); // Update with filtered results
      setLocations(response.data.locations);

      if (response.data.center) {
        setCenter({
          lat: response.data.center.latitude,
          lng: response.data.center.longitude,
        });
      }
    } catch (error) {
      console.error('Error performing filter search:', error);
    }
  };

  // Function to set the selected profession in the search input and clear dropdown
  const addItem = (item) => {
    setSearchTerm(`${item.name}`); // Set the search term to selected item
    setProfessionResults([]); // Clear the dropdown results
  };

  const handlePostCodeChange = (e) => {
    setPostCode(e.target.value);
  };

  const handleClear = () => {
    setSearchTerm('');
    setProfessionResults([]); // Clear profession results
  };



  const handleSearchChange = async (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    if (searchValue.length > 0) {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/Profession/searchprofessions?search=${searchValue}`);
        setProfessionResults(response.data.slice(0, MAX_RESULTS)); // Use professionResults
      } catch (error) {
        console.error('Error fetching professions:', error);
      }
    } else {
      setProfessionResults([]);
    }
  };

  // Perform search when submit button is clicked
  const handleSearchSubmit = async () => {
    if (searchTerm && postCode) {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/search/searchprofpostcode`, {
          searchTerm,
          postCode,
        });
        
        console.log("Professions data:", response.data.professions);
        console.log("Certifications data:", response.data.certifications);

        setResults(response.data.businesses); // Final search results
        setLocations(response.data.locations);
        setProfessionIds(response.data.professions);
        setAccreditations(response.data.certifications);

        if (response.data.center) {
          setCenter({
            lat: response.data.center.latitude,
            lng: response.data.center.longitude,
          });
        }
      } catch (error) {
        console.error('Error performing search:', error);
      }
    }
  };




  // Handle applying filters in MapSearch
const handleApplyFilters = (appliedFilters) => {
  console.log('Filters received from FilterForm:', appliedFilters);  // Debugging
  setFilters(appliedFilters);
  setPostCode(appliedFilters.postcode); // Sync the postcode from filters with MapSearch
  handleFilterSearch(appliedFilters);   // Call the filter search function with all filters
  setShowFilters(false);
};

  return (
    <div className="dashboard-home-owner">

      <Navbar />

      {/* Search Bar Section */}
      <div className="search-bar-container">
        <div className="search-bar">
          {/* Postcode Input */}
          <div className="postcode-box">
            <input
              type="text"
              value={postCode}
              onChange={handlePostCodeChange}
              placeholder="Postcode"
              className="postcode-input"
            />
          </div>

          <div className="search-frame">
            <div className="search-box">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Profession search"
                className="search-input"
              />
              <button onClick={handleClear} className="clear-button">×</button>
            </div>

            {professionResults.length > 0 && (
              <ul className="dropdown-results">
                {professionResults.map((item) => (
                  <li key={`${item.type}-${item.id}`} className="result-item">
                    <div 
                      className="result-item-content" 
                      onClick={() => addItem(item)} // Trigger addItem on div click
                    >
                      <span>{item.name}</span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>


          {/* Filters Button 
          <button onClick={() => setShowFilters(!showFilters)} className="filters-button">
            Filters
          </button>
            */}
          {/* Button to toggle the visibility of the filter form */}
          <button onClick={() => setShowFilters(!showFilters)} className="filters-button">
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </button>


          {/* Search Button */}
          <button onClick={handleSearchSubmit} className="search-button">
            <img src="/img/img-search-magnify.png" alt="Search" className="search-icon" />
          </button>
        </div>



        <div className={`filters-popup ${showFilters ? 'visible' : 'hidden'}`}>
        <FilterForm
          onApplyFilters={handleApplyFilters}
          onCloseFilters={() => setShowFilters(false)} 
          initialPostCode={filters.postcode}
          filters={filters} // Pass the current filters state to FilterForm
          initaccreditations={accreditations}
        />
      </div>


      </div>



      {/* Map and Search Results */}
      <div className="section-search">
        <div className="frame-4">
          
          {/* LHS - Search Results */}
          <div className="frame-results">
          <Link to="/list-search" className="list-search-link">
    List Search
  </Link>
            <h3>Return {results.length} results for "{searchTerm}" in {postCode}</h3>
            <ul>
              {results.map((result, index) => (
                <li key={index} className="result-item">
                  <h4>{result.businessName}</h4>
                  <p>{result.streetNumber} {result.streetName}, {result.suburb}, {result.myState}, {result.postCode}</p>
                  <p>{result.phone ? `Phone: ${result.phone}` : null}</p>
                  <div className="button-group">
                    <button className="button">Message</button>
                    <button className="button-2">Invite to Job</button>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* RHS - Google Map */}
          <div className="RHS">
            <MapComponent locations={locations} center={center} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapSearch;