// src/components/ListSearch.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../components/navybar';
import { API_BASE_URL } from '../config';
import { Link } from 'react-router-dom';
import FilterForm from './filterForm'; // Your filter form component
import './listSearch.css'; // Specific styles for this component

// Helper function to parse URL query parameters
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ListSearch = () => {
  const query = useQuery();
  const searchTermFromQuery = query.get('searchTerm');
  const postCodeFromQuery = query.get('postCode');

  const [searchTerm, setSearchTerm] = useState(searchTermFromQuery || '');
  const [postCode, setPostCode] = useState(postCodeFromQuery || '');
  const MAX_RESULTS = 20;
  const [professionResults, setProfessionResults] = useState([]); // For profession search results
  const [results, setResults] = useState([]); // To store the search results

  const [professionids, setProfessionIds] = useState([]);
  const [accreditations, setAccreditations] = useState([]);

  const [filters, setFilters] = useState({
    keywordSearch: '',
    within: '25km',
    selectedAccreditations: [],
    workScope: {},
    ratings: '',
    licensed: false,
    insured: false,
    options: {},
    // Add more filters as needed
  });

  const [showSearchResults, setShowSearchResults] = useState(false); // Flag to control visibility of form and results

  const handleSearchChange = async (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    if (searchValue.length > 0) {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/Profession/searchprofessions?search=${searchValue}`);
        setProfessionResults(response.data.slice(0, MAX_RESULTS)); // Use professionResults
      } catch (error) {
        console.error('Error fetching professions:', error);
      }
    } else {
      setProfessionResults([]);
    }
  };

  const handleFilterSearch = async (appliedFilters) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/search/searchwithfilters`, {
        searchTerm: searchTerm,
        keywordSearch: appliedFilters.keywordSearch, // Use search term or keyword
        postCode: appliedFilters.postcode || postCode,
        //within: appliedFilters.within,
        workScope: appliedFilters.workScope,
        ratings: appliedFilters.ratings,
        options: appliedFilters.options,
        selectedAccreditations: appliedFilters.selectedAccreditations,
      });

      setResults(response.data.businesses); // Update with filtered results
      //setLocations(response.data.locations);

    } catch (error) {
      console.error('Error performing filter search:', error);
    }
  };


  const handleSearchSubmit = async () => {
    // Clear previous results before fetching new ones
    setResults([]);
    if (searchTerm && postCode) {
        // Fetch search results based on filters
        try {
        const response = await axios.post(`${API_BASE_URL}/api/search/searchprofpostcode`, {
            searchTerm,
            postCode,
        });
    
        // Check if businesses exist in the response
        if (response.data.businesses && response.data.businesses.length > 0) {
            
            setResults(response.data.businesses); // Final search results
            //setLocations(response.data.locations);
            setProfessionIds(response.data.professions);
            setAccreditations(response.data.certifications);


            console.log("Businesses found:", response.data);
        } else {
            setResults([]); // Clear results if no businesses found
            console.log("No businesses found.");
        }
    
        setShowSearchResults(true); // Show the filter form and results section once search is clicked
        } catch (error) {
        console.error('Error fetching search results:', error);
    
        // Check for 404 (Not Found) and handle it by clearing results
        if (error.response && error.response.status === 404) {
            console.log("No matching professions found.");
            setResults([]); // Clear results if a 404 error occurs
        } else {
            // For other errors, you may want to log the error or handle it differently
            console.log('An error occurred during search.');
        }
        }
    }
  };      
      

  // Function to set the selected profession in the search input and clear dropdown
  const addItem = (item) => {
    setSearchTerm(`${item.name}`); // Set the search term to selected item
    setProfessionResults([]); // Clear the dropdown results

    console.log(`Selected profession: ${item.name}`);

  };

  const handlePostCodeChange = (e) => {
    setPostCode(e.target.value);
  };

  const handleClear = () => {
    setSearchTerm('');
    setProfessionResults([]); // Clear profession results
  };

   // Handle applying filters in MapSearch
    const handleApplyFilters = (appliedFilters) => {
        console.log('Filters received from FilterForm:', appliedFilters);  // Debugging
        setFilters(appliedFilters);
        setPostCode(appliedFilters.postcode); // Sync the postcode from filters with MapSearch
        handleFilterSearch(appliedFilters);   // Call the filter search function with all filters
    };

  return (
    <div>
      {/* Navbar at the top */}
      <Navbar />

      {/* Search Bar Section */}
      <div className="search-bar-container">
        <div className="search-bar">
          {/* Postcode Input */}
          <div className="postcode-box">
            <input
              type="text"
              value={postCode}
              onChange={handlePostCodeChange}
              placeholder="Postcode"
              className="postcode-input"
            />
          </div>

          <div className="search-frame">
            <div className="search-box">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Profession search"
                className="search-input"
              />
              <button onClick={handleClear} className="clear-button">×</button>
            </div>

            {professionResults.length > 0 && (
              <ul className="dropdown-results">
                {professionResults.map((item) => (
                  <li key={`${item.type}-${item.id}`} className="result-item">
                    <div
                      className="result-item-content"
                      onClick={() => addItem(item)} // Trigger addItem on div click
                    >
                      <span>{item.name}</span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Search Button */}
          <button onClick={handleSearchSubmit} className="search-button">
            <img src="/img/img-search-magnify.png" alt="Search" className="search-icon" />
          </button>
        </div>
      </div>

      {/* Show Filters and Results Only After Search is Triggered */}
      {showSearchResults && (
        
        <div className="list-search-container">

          {/* LHS - Filters Form */}
          <div className="filter-section">
            <Link to="/map-search" className="list-search-link">
                Map Search
            </Link>

            <FilterForm onApplyFilters={handleApplyFilters} 
                      initialPostCode={filters.postcode}
                      filters={filters} // Pass the current filters state to FilterForm
                      initaccreditations={accreditations} />
          </div>

          {/* RHS - Search Results */}
          <div className="results-section">
          {Array.isArray(results) && results.length === 0 ? (
    <p>No results found.</p>
  ) : (
    results.map((result, index) => (
      <div key={index} className="search-result">
        <div className="search-container">
          {/* Business Logo and Name */}
          <div className="business-header">
            <img
              className="business-logo"
              src={'img/pngtree-home-vector-logo-design-template-5137686-1.png'}
              alt="Business Logo"
            />
            <div className="business-info">
              <h2 className="business-name">{result.businessName}</h2>
              <p className="distance">{4 || 3} Km Away</p>
            </div>
          </div>

          {/* Business Description */}
          <p className="business-description">{result.description}</p>


          {/* Rating and Contact Info */}
          <div className="business-details">
            <div className="business-rating">
              <img className="rating-icon" src="img/image.png" alt="Rating" />
              <p className="rating">{result.OverallRating} ★ ({result.OverallRating} Reviews)</p>
            </div>

            <div className="business-contact">
              <img className="contact-icon" src="img/image-1.png" alt="Contact Info" />
              <div className="contact-details">
                <p>{result.phone || "Phone not available"}</p>
                <p>{result.email || "Email not available"}</p>
              </div>
            </div>
          </div>

          {/* Call to Action Buttons */}
          <div className="business-actions">
            <button className="contact-now">Contact Now</button>
            <button className="view-profile">View Profile</button>
          </div>
        </div>
      </div>
    ))
  )}
</div>
        </div>
      )}
    </div>
  );
};

export default ListSearch;