/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Size16 } from "../../icons/Size16";
import "./style.css";

export const SelectField = ({
  description = "Description",
  error = "Error",
  hasDescription = false,
  open = false,
  value = "Value",
  hasLabel = true,
  hasError = true,
  label = "Label",
  state,
  valueType,
  className,
  selectClassName,
}) => {
  return (
    <div className={`select-field ${className}`}>
      {hasLabel && <div className={`label ${state}`}>{label}</div>}

      {["default", "disabled"].includes(state) && (
        <div className={`select state-${state} ${state === "default" ? selectClassName : undefined}`}>
          <div className={`value state-0-${state} value-type-${valueType}`}>{value}</div>
          <Size16 className="chevron-down" color={state === "disabled" ? "#B3B3B3" : "#1E1E1E"} />
        </div>
      )}

      {state === "error" && (
        <>
          <div className="div">
            <div className={`text-wrapper value-type-0-${valueType}`}>{value}</div>
            <Size16 className="chevron-down" color="#1E1E1E" />
          </div>
          <>{hasError && <div className="error-2">{error}</div>}</>
        </>
      )}
    </div>
  );
};

SelectField.propTypes = {
  description: PropTypes.string,
  error: PropTypes.string,
  hasDescription: PropTypes.bool,
  open: PropTypes.bool,
  value: PropTypes.string,
  hasLabel: PropTypes.bool,
  hasError: PropTypes.bool,
  label: PropTypes.string,
  state: PropTypes.oneOf(["disabled", "error", "default"]),
  valueType: PropTypes.oneOf(["placeholder", "default"]),
};
