import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './navbar.css'; // Import the CSS file in the same folder

const NavigationBarDashHome = ({ handleLogoutClick, prefix }) => {


    const navigate = useNavigate(); 

  return (
    <div className="section-header">
      <div className="header-container">
        {/* Logo and Title */}
        <Link to="/" className="lhs-logo">
          <img className="image-197" src="img/image-197.png" alt="Build Start logo" />
          <div className="build-start">Build Start</div>
        </Link>

        {/* Navigation Links */}
        <div className="navbar">
          <Link to="/homedash">
            <div className="text-wrapper-2">Dashboard</div>
          </Link>
          <Link to="/business-home">
            <div className="text-wrapper-2">Jobs</div>
          </Link>
          <Link to="/directory">
            <div className="text-wrapper-2">Directory</div>
          </Link>
          <Link to="/pricing">
            <div className="text-wrapper-2">Message</div>
          </Link>
          <Link to="/info-guides">
            <div className="text-wrapper-2">Socials</div>
          </Link>
        </div>

        {/* Login and Sign-Up Buttons */}
        <div className="login-buttons">
          <button className="frame-3 signup-button" onClick={handleLogoutClick}>
            <div className="sign-up">{prefix} Log Out</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavigationBarDashHome;