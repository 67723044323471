// src/components/FilterForm.js
import React, { useState } from 'react';
import { useEffect } from 'react';
import './FilterForm.css'; // Add necessary CSS

const FilterForm = ({ onApplyFilters, onCloseFilters, filters, initialPostCode, initaccreditations }) => {
    const [postcode, setPostcode] = useState(initialPostCode);
    const [keywordSearch, setKeywordSearch] = useState(filters?.keywordSearch || '');
    const [within, setWithin] = useState(filters?.within || '25km');
    const [accreditations, setAccreditations] = useState(initaccreditations);
    const [selectedAccreditations, setSelectedAccreditations] = useState(filters?.selectedAccreditations || []);
    
    // Always default workScope to true for residential, commercial, government
    const [workScope, setWorkScope] = useState({
      residential: true,
      commercial: true,
      government: true,
    });
  
    const [licensed, setLicensed] = useState(filters?.licensed || false);
    const [insured, setInsured] = useState(filters?.insured || false);
    const [ratings, setRatings] = useState(filters?.ratings || '4 stars +');
    const [options, setOptions] = useState({
      afterHours: true,
      weekends: false,
    });

    //const accreditationsList = ['Level 3', 'Level 2', 'Level 1', 'Metering', 'Overhead Connections'];
  

  useEffect(() => {
    // Sync postcode state when the initialPostCode prop changes
    setPostcode(initialPostCode);
  }, [initialPostCode]);

  useEffect(() => {
    // Sync postcode state when the initialPostCode prop changes
    setAccreditations(initaccreditations);
  }, [initaccreditations]);


  useEffect(() => {
    setKeywordSearch(filters?.keywordSearch || '');
    setWithin(filters?.within || '25km');

    setSelectedAccreditations(filters.selectedAccreditations || []);

    setLicensed(filters?.licensed || false);
    setInsured(filters?.insured || false);
    setRatings(filters?.ratings || '3 stars +');

    setOptions({
        afterHours: filters?.options.afterHours ?? false,
        weekends: filters?.options.weekends ?? false,
    });
    
    // Merge default workScope with filters if they exist
    setWorkScope({
      residential: filters?.workScope?.residential ?? true,  // Default to true
      commercial: filters?.workScope?.commercial ?? true,    // Default to true
      government: filters?.workScope?.government ?? true,    // Default to true
    });
  }, [filters]);





  const handleAccreditationChange = (certificationId) => {
    if (selectedAccreditations.includes(certificationId)) {
      setSelectedAccreditations(selectedAccreditations.filter((id) => id !== certificationId));
    } else {
      setSelectedAccreditations([...selectedAccreditations, certificationId]);
    }
  };



  const handleRatingChange = (e) => {
    setRatings(e.target.value);
  };

  const handleWorkScopeChange = (e) => {
    setWorkScope({ ...workScope, [e.target.name]: e.target.checked });
  };

  const handleOptionChange = (e) => {
    setOptions({ ...options, [e.target.name]: e.target.checked });
  };

// Ensure this method is called when the 'Apply Filters' button is pressed
const handleApplyFilters = () => {
    const filters = {
      postcode,
      within,
      keywordSearch,
      selectedAccreditations, 
      workScope,  // Ensure workScope is passed
      ratings,
      options,
      licensed,
      insured
    };
  
    console.log('Applying filters:', filters);  // Debugging to see what is being passed
    onApplyFilters(filters); // Pass filters back to MapSearch
  };


  return (
    <div className="filter-form">

    <div className="filter-section">
        <div className="filter-header">
            <h2 className="filter-title">Filters</h2>
            <span className="filter-close" onClick={onCloseFilters}>×</span> 
        </div>
    </div>

    {/* Use this row if you want two or more items on one row*/}
    <div className="filter-row">

  <div className="filter-section">
    <label>Postcode</label>
    <input
      type="text"
      value={postcode}
      onChange={(e) => setPostcode(e.target.value)}
      className="input-field"
    />
  </div>

  <div className="filter-section">
    <label>Within</label>
    <select
      value={within}
      onChange={(e) => setWithin(e.target.value)}
      className="input-field"
    >
      <option>5km</option>
      <option>10km</option>
      <option>25km</option>
      <option>50km</option>
    </select>
  </div>
</div>

      <div className="filter-section">
        <label>Keyword Search</label>
        <textarea
          value={keywordSearch}
          onChange={(e) => setKeywordSearch(e.target.value)}
          className="input-field"
          placeholder="Keywords to search business table columns"
        />
      </div>

      {/* Render accreditation checkboxes */}
      <div className="filter-section scrollable-list">
        <label>Accreditations</label>
        <div className="accreditations">
        {accreditations?.map((acc) => (
            <label key={acc.certificationId}>
              <input
                type="checkbox"
                value={acc.certificationId}
                checked={selectedAccreditations.includes(acc.certificationId)}
                onChange={() => handleAccreditationChange(acc.certificationId)}
              />
              &nbsp;{acc.name}
            </label>
          ))}
        </div>
      </div>

      <div className="filter-section">
        <label>Work Scope</label>
        <div className="work-scope">
          <label>
            <input
              type="checkbox"
              name="residential"
              checked={workScope.residential}
              onChange={handleWorkScopeChange}
            />
            &nbsp;Residential
          </label>
          <label>
            <input
              type="checkbox"
              name="commercial"
              checked={workScope.commercial}
              onChange={handleWorkScopeChange}
            />
            &nbsp;Commercial
          </label>
          <label>
            <input
              type="checkbox"
              name="government"
              checked={workScope.government}
              onChange={handleWorkScopeChange}
            />
            &nbsp;Government
          </label>
        </div>
      </div>

      <div className="filter-section">
        <label>Ratings</label>
        <div className="ratings">
        <label>
            <input
              type="radio"
              value="5 stars"
              checked={ratings === '5 stars'}
              onChange={handleRatingChange}
            />
            &nbsp;5 stars
          </label>
          <label>
            <input
              type="radio"
              value="4 stars +"
              checked={ratings === '4 stars +'}
              onChange={handleRatingChange}
            />
            &nbsp;4 stars +
          </label>
          <label>
            <input
              type="radio"
              value="3 stars +"
              checked={ratings === '3 stars +'}
              onChange={handleRatingChange}
            />
            &nbsp;3 stars +
          </label>
          <label>
            <input
              type="radio"
              value="2 stars +"
              checked={ratings === '2 stars +'}
              onChange={handleRatingChange}
            />
            &nbsp;2 stars +
          </label>



        </div>
      </div>

      <div className="filter-section">
        <label>Options</label>
        <div className="options">
          <label>
            <input
              type="checkbox"
              name="afterHours"
              checked={options.afterHours}
              onChange={handleOptionChange}
            />
            &nbsp;&nbsp;After hours
          </label>
          <label>
            <input
              type="checkbox"
              name="weekends"
              checked={options.weekends}
              onChange={handleOptionChange}
            />
            &nbsp;&nbsp;Weekends
          </label>
        </div>
      </div>

      <div className="filter-section toggles">
        <label>&nbsp;Licensed</label>
        <label className="switch">
          <input
            type="checkbox"
            checked={licensed}
            onChange={() => setLicensed(!licensed)}
          />
          <span className="slider round"></span>
        </label>
        
        <label>&nbsp;Insured</label>
        <label className="switch">
          <input
            type="checkbox"
            checked={insured}
            onChange={() => setInsured(!insured)}
          />
          <span className="slider round"></span>
        </label>
      </div>

      <button className="apply-filters-button" onClick={handleApplyFilters}>
        Apply filters
      </button>
    </div>
  );
};

export default FilterForm;