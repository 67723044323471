import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomePage from './HomePage1';
import HomeUserLanding from './HomeUserLanding';
import BusinessLanding from './BusinessLanding';

import RegistrationForm from './register/registerhomeuser';
import RegistrationFormOld from './register/registerhomeuser_nonformat';

import BusinessRegistrationForm from './register/registerbusinessuser'; // Create this component
import HomeUserOnboarding01 from './onboarding/homeonboarding01';
import HomeUserOnboarding02 from './onboarding/homeonboarding02';
import HomeUserOnboarding015 from './onboarding/homeonboarding015';  // Create this component
import BusinessUserOnboarding from './onboarding/businessonboarding01'; // Create this component

import HomeUserLogin from './login/homeuserlogin'; // Create this component
import BusinessUserLogin from './login/businessuserlogin';
import BusinessUserLoginOld from './login/businessuserlogin_old';
 // Create this component
import CheckUser from './register/checkuser';
import HomeDash from './home/homedash';
import BusinessDashboard from './home/businessdash';
import TestSeach from './search/professionSearch'
import FullEntitySearch from './search/fullEntitySearch';
//import HomeUserOnboarding from './search/professionSearch'; 

import BusinessOnboarding01 from './onboarding/businessonboarding01';
import BusinessOnboarding02 from './onboarding/businessonboarding02';
import BusinessOnboarding03 from './onboarding/businessonboarding03';
import BusinessOnboarding04 from './onboarding/businessonboarding04';
import BusinessOnboarding05 from './onboarding/businessonboarding05';

import JobsDashboard from './jobs/JobsDashboard';
import NewJobPage from './jobs/NewJobPage';
import BusinessJobsDashboard from './jobs/BusinessJobsDashboard';
import AddjobsCompleted from './jobs/AddjobsCompleted';


import 'bootstrap/dist/css/bootstrap.min.css';
import './search/search.css';

import MapSearch from './search/mapsearch';
import ListSearch from './search/listSearch';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/home1" element={<HomePage />} />
        <Route path="/" element={<HomeUserLanding />} />
        <Route path="/business-home" element={<BusinessLanding />} />

        <Route path="/map-search" element={<MapSearch />} />
        <Route path="/list-search" element={<ListSearch />} />

        <Route path="/register-home-user" element={<RegistrationForm />} />
        <Route path="/register-home-user_old" element={<RegistrationFormOld/>} />

        <Route path="/register-business-user" element={<BusinessRegistrationForm />} />
        <Route path="/home-user-onboarding-01" element={<HomeUserOnboarding01 />} />
        <Route path="/home-user-onboarding-02" element={<HomeUserOnboarding02 />} />
        <Route path="/home-user-onboarding-015" element={<HomeUserOnboarding015 />} />
        <Route path="/testsearch" element={<BusinessUserOnboarding />} />

        <Route path="/home-user-login" element={<HomeUserLogin />} />
        <Route path="/business-user-login" element={<BusinessUserLogin />} />
        <Route path="/business-user-login-old" element={<BusinessUserLoginOld />} />

        <Route path="/checkuser" element={<CheckUser />} />
        <Route path="/homedash" element={<HomeDash/>} />
        <Route path="/businessdash" element={<BusinessDashboard/>} />
        <Route path="/testsearch" element={<TestSeach/>} />
        <Route path="/fullsearch" element={<FullEntitySearch/>} />

        <Route path="/home-user-onboarding-015" element={<HomeUserOnboarding015 />} />

        <Route path="/business-onboarding-01" element={<BusinessOnboarding01 />} />
        <Route path="/business-onboarding-02" element={<BusinessOnboarding02 />} />
        <Route path="/business-onboarding-03" element={<BusinessOnboarding03 />} />
        <Route path="/business-onboarding-04" element={<BusinessOnboarding04 />} />
        <Route path="/business-onboarding-05" element={<BusinessOnboarding05 />} />

        <Route path="/jobs/JobsDashboard" element={<JobsDashboard />} />
        <Route path="./jobs/NewJobPage" element={<NewJobPage />} />
        <Route path="./jobs/BusinessJobsDashboard" element={<BusinessJobsDashboard />} />
        <Route path="./jobs/AddjobsCompleted" element={<AddjobsCompleted />} />

      </Routes>
    </Router>
  );
}

export default App;