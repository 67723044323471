import React from "react";
import { MenuItem } from "../components/MenuItem";
import Navbar from '../components/navybar';

import "./jobsdashboard.css";

export const JobsDashboard = () => {
  return (
    <div className="homeowner-jobs">

<Navbar />

      <div className="section-new-job">
        <div className="frame-2">
          <div className="frame-3">
            <div className="subtitle-wrapper">
              <div className="subtitle">Post a new job</div>
            </div>
            <div className="frame-4" />
          </div>
          <div className="frame-search">
            <div className="search">
              <div className="text-wrapper-2">Where</div>
              <div className="text-wrapper-3">Postcode</div>
              <div className="text-wrapper-4">Describe your job</div>
              <p className="p">What do you need to get done?</p>
              <div className="arrow-right-wrapper">
              <img src="/img/img-menu3.png" alt="Menu" width="30" height="30" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-my-jobs">
        <div className="top-title">
          <div className="subtitle-2">My Jobs</div>
        </div>
        <div className="frame-my-jobs">
          <div className="frame-5">
            <div className="frame-6">
              <div className="frame-7">
                <div className="frame-8">
                  <div className="text-wrapper-5">Perform sewer pegout</div>
                </div>
                <div className="menu-wrapper">
                <img src="/img/img-menu3.png" alt="Menu" width="30" height="30" />
                </div>
              </div>
              <div className="frame-9">
                <div className="div">Cabramatta West</div>
              </div>
              <div className="frame-10">
                <p className="div">Need a plumber to perform sewer pegout....</p>
              </div>
              <div className="frame-10">
                <div className="div">Status: Open</div>
              </div>
              <div className="frame-11">
                <div className="div">Quotes: 3</div>
              </div>
              <div className="frame-12">
                <div className="text-wrapper-6">Hire for this job</div>
              </div>
            </div>
            <div className="frame-13">
              <div className="overlap">
                <div className="overlap-group">
                  <div className="text-wrapper-7">Pave side pathway</div>
                  <div className="menu-3-wrapper">
                  <img src="/img/img-menu3.png" alt="Menu" width="30" height="30" />
                  </div>
                </div>
                <div className="text-wrapper-8">Fairfield</div>
              </div>
              <p className="text-wrapper-9">Require a gardener to pave the side of ....</p>
              <div className="text-wrapper-10">Status: Completed</div>
              <div className="overlap-group-2">
                <div className="text-wrapper-11">Quotes: 3</div>
                <div className="text-wrapper-11">Quotes:</div>
              </div>
              <div className="text-wrapper-12">Hired: Cabramatta Horticulture</div>
            </div>
            <div className="menu-2">
              <div className="menu-section">
                <MenuItem
                  className="menu-item-instance"
                  hasDescription={false}
                  hasIcon={false}
                  hasShortcut={false}
                  label="View this job"
                  stateProp="default"
                />
                <MenuItem
                  className="menu-item-instance"
                  hasDescription={false}
                  hasIcon={false}
                  hasShortcut={false}
                  label="Edit this job"
                  stateProp="default"
                />
                <MenuItem
                  className="design-component-instance-node"
                  hasDescription={false}
                  hasIcon={false}
                  label="Duplicate this job"
                  menuShortcutHasDiv={false}
                  stateProp="default"
                />
                <MenuItem
                  className="design-component-instance-node"
                  hasDescription={false}
                  hasIcon={false}
                  hasShortcut={false}
                  label="Review this job"
                  stateProp="default"
                />
              </div>
            </div>
            <img className="line" alt="Line" src="/img/line-1.svg" />
          </div>
          <img className="img" alt="Line" src="/img/line-2.svg" />
        </div>
      </div>
      <div className="section-my-hires">
        <div className="frame-14">
          <div className="subtitle-3">My Hires</div>
        </div>
        <div className="frame-15">
          <div className="frame-16">
            <div className="frame-17">
              <div className="text-wrapper-13">Fencing 4U</div>
              <div className="menu-3-instance-wrapper">
              <img src="/img/img-menu3.png" alt="Menu" width="30" height="30" />
              </div>
            </div>
            <div className="frame-18">
              <div className="text-wrapper-14">Cabramatta West</div>
              <p className="text-wrapper-15">We can do all things in making your garden..</p>
              <div className="text-wrapper-16">Rating:</div>
            </div>
            <div className="frame-19">
              <div className="text-wrapper-17">Quotes: 3</div>
              <div className="text-wrapper-18">Jobs Completed: 3</div>
            </div>
          </div>
          <div className="frame-16">
            <div className="frame-17">
              <div className="text-wrapper-13">Cabramatta Horticulture</div>
              <div className="menu-3-instance-wrapper">
              <img src="/img/img-menu3.png" alt="Menu" width="30" height="30" />
              </div>
            </div>
            <div className="frame-18">
              <div className="text-wrapper-14">Cabramatta West</div>
              <p className="text-wrapper-15">We can do all things in making your garden..</p>
              <div className="text-wrapper-16">Rating:</div>
            </div>
            <div className="frame-19">
              <div className="text-wrapper-17">Quotes: 3</div>
              <div className="text-wrapper-19">Jobs Completed: 10</div>
            </div>
          </div>
          <div className="menu-3">
            <div className="menu-section">
              <MenuItem
                className="menu-item-instance"
                hasDescription={false}
                hasIcon={false}
                hasShortcut={false}
                label="Open Profile"
                stateProp="default"
              />
              <MenuItem
                className="design-component-instance-node"
                hasDescription={false}
                hasIcon={false}
                label="Invite to Job"
                menuShortcutHasDiv={false}
                stateProp="default"
              />
              <MenuItem
                className="design-component-instance-node"
                hasDescription={false}
                hasIcon={false}
                hasShortcut={false}
                label="Message"
                stateProp="default"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default JobsDashboard;