
import React, { useState } from 'react';
import { API_BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import NavigationBar from '../components/navbar'; 
import NavigationBarDashHome from '../components/navbarhomedash';

import { Link } from 'react-router-dom'; 
import '../styles/home-user-landing-page.css';
import '../styles/globals.css';


function BusinessDashboard() {


  const navigate = useNavigate(); 

  const handleLoginClick = () => {
    navigate('/business-user-login'); // Navigate to the login page when clicked
  };

  const handleButtonClick = (path) => {
    navigate(path);
  };


  return (
    <div className="home-user-landing-page">
      
      <NavigationBarDashHome handleLogoutClick={handleLoginClick} prefix={''} />

      
      <h2>Business Dashboard</h2>
      <p>Welcome to the Business Dashboard!</p>

      <div style={{marginTop: '30px'}}>
          <button onClick={() => handleButtonClick('/business-onboarding-01')} style={{ margin: '10px', padding: '10px 20px' }}>
            Business Onboarding Step 1
          </button>

          <button onClick={() => handleButtonClick('/business-onboarding-02')} style={{ margin: '10px', padding: '10px 20px' }}>
            Business Onboarding Step 2
          </button>

          <button onClick={() => handleButtonClick('/business-onboarding-03')} style={{ margin: '10px', padding: '10px 20px' }}>
            Business Onboarding Step 3
          </button>

          <button onClick={() => handleButtonClick('/business-onboarding-04')} style={{ margin: '10px', padding: '10px 20px' }}>
            Business Onboarding Step 4
          </button>

          <button onClick={() => handleButtonClick('/business-onboarding-05')} style={{ margin: '10px', padding: '10px 20px' }}>
            Business Onboarding Step 5
          </button>

     </div>


    </div>
  );
}

export default BusinessDashboard;