// src/components/Navbar.js
import React from 'react';
import './navybar99.css'; // Importing the CSS file

const Navybar = () => {
  return (
    <nav className="navbar99">
      <div className="navbar-content">
        <div className="logo-section">
          <img
            src="/img/img-logo.png"
            alt="Build Start Logo"
            className="logo"
          />
          <span className="brand-name">Build Start</span>
        </div>
        <div className="nav-links">
        <a href="/jobs/JobsDashboard" className="nav-item">Home</a>
        <a href="/map-search" className="nav-item">Search</a>
          <a href="/jobs/JobsDashboard" className="nav-item">Jobs</a>
          <a href="/directory" className="nav-item">Browse Directory</a>
          <a href="/messages" className="nav-item">Messages</a>
          <a href="/social" className="nav-item">Social Platform</a>
        </div>
        <div className="profile-section">
          <img
            src="/img/img-profile.png"
            alt="Profile Icon"
            className="profile-icon"
          />
        </div>
      </div>
    </nav>
  );
};

export default Navybar;