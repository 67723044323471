import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus, FaTimes, FaUser, FaWrench, FaWarehouse, FaBook } from 'react-icons/fa'; // Import icons

import { API_BASE_URL } from '../config';
import '../styles/general.css'; 
import '../styles/professionsearch.css'; 

const BusinessOnboarding03 = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const MAX_RESULTS = 30;

  const Spacer = ({ height }) => {
    return <div style={{ height }} />;
  };


  // Handle typing in the search box and dynamically fetch professions
  const handleSearchChange = async (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    if (searchValue.length > 0) {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/Search/searchproducts?search=${searchValue}`);
        setResults(response.data.slice(0, MAX_RESULTS)); // Limit to MAX_RESULTS
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    } else {
      setResults([]);
    }
  };

  
  // Clear both search term and results
const handleClear = () => {
  setSearchTerm('');    // Clear the search input
  setResults([]);       // Clear the results
};

  // Add selected profession to the list
  const addItem = (item) => {
    if (!selectedItems.some((p) => p.id === item.id)) {
      setSelectedItems((prevSelected) => [...prevSelected, item]);
    }
  };


  // Remove selected profession from the list
  const removeItem = (itemId) => {
    setSelectedItems(selectedItems.filter((p) => p.id !== itemId));
  };

  const handleSubmit = async () => {

  
    if (selectedItems.length === 0) {
      alert('Please select at least one product.');
      return;
    }
  
    const data = {
      businessid: 1,
      productIds: selectedItems
        .filter(item => item.type === 'Product')
        .map((item) => item.id), // Filter and map profession IDs

    };
  
    try {
      await axios.post(`${API_BASE_URL}/api/BusinessOnboarding/savebusinessproducts`, data);
      alert('Business onboarding saved successfully!');
    } catch (error) {
      console.error('Error saving business onboarding:', error);
      alert('An error occurred while saving. Please try again.');
    }
  };

  return (
    <div className="business-onboarding-container">
      <h1>What are the Products you provide?</h1>

      <Spacer height="20px" />

      <h4>Type in the products you provide.</h4>
      <h4>Click on the matching products to add them</h4>

      <Spacer height="40px" />

      <div className="search-box">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Products search"
          className="search-input"
        />
        <button onClick={handleClear} className="clear-button">×</button>
      </div>

      {results.length > 0 && (
        <ul className="dropdown-results2">
          {results.map((item) => (
            <li key={`${item.type}-${item.id}`}  className="result-item">
              <div className="result-item-content">
                {item.type === 'Profession' && <FaUser />}
                {item.type === 'Service' && <FaWrench />}
                {item.type === 'ProfessionAncillary' && <FaBook />} {/* Book icon for ProfessionAncillary */}
                {item.type === 'Product' && <FaWarehouse />}  {/* Warehouse icon for SupplierType */}
                <span>{item.name} ({item.type})</span>
            </div>

              <button onClick={() => addItem(item)} className="add-button">
                +
              </button>
            </li>
          ))}
        </ul>
      )}

    <Spacer height="20px" />

    <div className="selected-items">
      <h4>Selected Products</h4>
      <div className="item-tags" style={{ minHeight: '200px', border: '2px dashed #ccc', borderRadius: '10px', padding: '10px', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
        {selectedItems.length > 0 ? (
          selectedItems.map((item) => (
            <div key={`${item.type}-${item.id}`} className="item-tag" style={{ height: '50px',backgroundColor: '#f0f0f0', borderRadius: '20px', padding: '10px 15px', margin: '5px', display: 'flex', alignItems: 'center' }}>
              <span>{item.name}</span>
              <button onClick={() => removeItem(item.id)} className="remove-button" style={{ background: 'none', border: 'none', marginLeft: '10px', cursor: 'pointer' }}>×</button>
            </div>
          ))
        ) : (
          <p style={{ color: '#999' }}>No products selected</p>
        )}
      </div>
    </div>

    <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '20px' }}>

          <button onClick={handleSubmit} className="submit-button">Next</button>

          <button
            
            style={{
              padding: '10px 20px',
              backgroundColor: 'transparent',
              color: '#007bff',
              border: '2px solid #007bff',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Skip
          </button>


        </div>


    </div>
  );
};

export default BusinessOnboarding03;