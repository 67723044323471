
import React, { useState } from 'react';
import { API_BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import NavigationBar from '../components/navbar'; 
import NavigationBarDashHome from '../components/navbarhomedash';

import { Link } from 'react-router-dom'; 
import '../styles/home-user-landing-page.css';
import '../styles/globals.css';


function Dashboard() {

  const navigate = useNavigate(); 

  const handleLoginClick = () => {
    navigate('/home-user-login'); // Navigate to the login page when clicked
  };

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <div className="home-user-landing-page">
      
      <NavigationBarDashHome handleLogoutClick={handleLoginClick} prefix={''} />

      
      <h2>Dashboard</h2>
      <p>Welcome to the Dashboard!</p>

      <div style={{marginTop: '30px'}}>
          <button onClick={() => handleButtonClick('/home-user-onboarding-01')} style={{ margin: '10px', padding: '10px 20px' }}>
            Home User Onboarding Step 1
          </button>
          <button onClick={() => handleButtonClick('/home-user-onboarding-015')} style={{ margin: '10px', padding: '10px 20px' }}>
            Home User Onboarding Step 2
          </button>
          <button onClick={() => handleButtonClick('/home-user-onboarding-02')} style={{ margin: '10px', padding: '10px 20px' }}>
            Home User Onboarding Step 3
          </button>
        </div>




    </div>
  );
}

export default Dashboard;