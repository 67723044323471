import React from "react";
import { SelectField } from "../components/SelectField";
import "./newjobpage.css";

export const NewJobPage = () => {
  return (
    <div className="dashboard-home-owner">
      <div className="section-header">
        <header className="header">
          <div className="frame">
            <img className="codesandbox" alt="Codesandbox" src="/img/codesandbox.svg" />
            <div className="text-wrapper-2">Build Start</div>
          </div>
          <div className="navigation-pill-list">
            <div className="navigation-pill">
              <div className="title">Home</div>
            </div>
            <div className="title-wrapper">
              <div className="title-2">Jobs</div>
            </div>
            <div className="div-wrapper">
              <div className="title">Browse Directory</div>
            </div>
            <div className="navigation-pill-2">
              <div className="title">Social Platform</div>
            </div>
            <div className="navigation-pill-3">
              <div className="title">Messages</div>
            </div>
          </div>
          <div className="user-wrapper">
          <img src="/img/img-menu3.png" alt="Menu" />
          </div>
        </header>
      </div>
      <div className="sectionnewjob">
        <div className="job-frame">
          <div className="subtitle-wrapper">
            <div className="subtitle">New Job Details</div>
          </div>
          <div className="frame-inputs">
            <div className="input-field">
              <div className="text-wrapper-3">New Job Title</div>
              <div className="input" />
            </div>
            <div className="input-field-2">
              <div className="text-wrapper-3">Postcode</div>
              <div className="input-2">
                <div className="text-wrapper-4">2166</div>
              </div>
            </div>
            <div className="input-field-3">
              <div className="text-wrapper-3">Job Description</div>
              <div className="input-3">
                <p className="text-wrapper-4">
                  We need a wardrobe person to provide a quote for a standard mirrored wardrobe 2m x 3m
                </p>
              </div>
            </div>
          </div>
          <div className="frame-inputs">
            <div className="frame-2">
              <p className="p">Add keywords for who and what needs to be done</p>
              <div className="frame-postcode">
                <div className="search">
                  <input className="search-for" placeholder="Search for profession or service" type="text" />
                  <img className="x" alt="X" src="/img/x.svg" />
                </div>
              </div>
              <div className="rectangle" />
            </div>
          </div>
          <div className="secction-new-job">
            <div className="frame-3">
              <div className="frame-4">
                <div className="subtitle-2">Time and Budget</div>
              </div>
              <div className="frame-5">
                <div className="input-field-4">
                  <p className="text-wrapper-5">Do you have a budget in mind?</p>
                  <div className="input-4">
                    <div className="text-wrapper-6">$500</div>
                  </div>
                </div>
                <SelectField
                  className="select-field-instance"
                  label="Select a budget"
                  selectClassName="design-component-instance-node"
                  state="default"
                  value="N/A"
                  valueType="default"
                />
              </div>
              <div className="frame-6">
                <div className="frame-7">
                  <p className="text-wrapper-7">When do you need this job done by?</p>
                </div>
                <div className="frame-8">
                  <div className="input-5">
                    <div className="text-wrapper-8">Urgently</div>
                  </div>
                  <div className="input-6">
                    <p className="text-wrapper-9">Next couple o f weeks</p>
                  </div>
                  <div className="input-7">
                    <div className="text-wrapper-10">More than 2 weeks</div>
                  </div>
                  <div className="input-8">
                    <div className="text-wrapper-11">Pick a date</div>
                  </div>
                </div>
              </div>
              <SelectField
                className="select-field-2"
                label="Site Visit times"
                selectClassName="design-component-instance-node"
                state="default"
                value="Anytime"
                valueType="default"
              />
            </div>
          </div>
          <div className="section-new-job">
            <div className="frame-4">
              <div className="subtitle-3">Upload Media</div>
            </div>
            <div className="frame-wrapper">
              <div className="frame-9">
                <div className="input-field-5">
                  <input
                    className="uploan-images-of-the"
                    placeholder="Uploan images of the job to help businesses provide quotes"
                    type="number"
                  />
                  <input className="input-9" placeholder="Upload image" type="number" />
                </div>
                <div className="frame-10">
                  <div className="rectangle-2" />
                  <div className="rectangle-2" />
                  <div className="rectangle-2" />
                  <div className="rectangle-2" />
                </div>
              </div>
            </div>
            <div className="frame-11">
              <div className="save-publish-job-wrapper">
                <div className="save-publish-job">Save &amp; Publish Job</div>
              </div>
              <div className="save-publish-job-wrapper">
                <div className="save-publish-job">Save &amp; Publish Job</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewJobPage;
